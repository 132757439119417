import { useEffect } from 'react';
import { useEnvironment } from '../../hooks/useEnvironment';
import { useUxSignalScript } from '../../hooks/useUxSignalScript';
import { tryParseJSON } from '../../utils/tryParseJSON';
import useSessionStorage from '../../hooks/useSessionStorage';

import './FeedbackPrompt.css';

const uxSignalsEvents = {
  isDisqualified: 'isDisqualified',
  closeWidget: 'closeWidget',
  bookingCompleted: 'bookingCompleted',
} as const;

export default function FeedBackPrompt() {
  const [shouldShowFeedbackPrompt, setShouldShowFeedbackPrompt] = useSessionStorage<boolean>('shouldShowFeedbackPrompt', true);

  useUxSignalScript(shouldShowFeedbackPrompt);
  const { isProd } = useEnvironment();

  const demoMode = !isProd() ? 'demo' : '';

  useEffect(() => {
    function handleMessage(e: MessageEvent) {
      if (typeof e.data !== 'string') return;

      const parsedData = tryParseJSON<{ command: keyof typeof uxSignalsEvents }>(e.data);
      if (!parsedData.success) return;

      if (parsedData.result.command === uxSignalsEvents.isDisqualified || parsedData.result.command === uxSignalsEvents.bookingCompleted) {
        setShouldShowFeedbackPrompt(false);
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!shouldShowFeedbackPrompt) return null;

  return <div data-uxsignals-embed="panel-xkf8r58rs3" data-uxsignals-mode={demoMode} />;
}
