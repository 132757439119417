import { invoicesLocalization } from './localizationsInvoices';
import { collaterals } from './localizationsCollaterals';
import { Localizations } from '../../utils/localizer';
import { loanDetails } from './localizationsLoanDetails';
import { grantDetails } from './localizationsGrantDetails';
import { paymentPlan } from './localizationsPaymentPlan';
import { guaranteeDetails } from './localizationsGuaranteeDetails';
import { footer } from './localizationsFooter';
import { transactionOverview } from './localizationsTransactionOverview';
import { interestSubsidyDetails } from './localizationsInterestSubsidyDetails'
import { customerDocuments } from './localizationsDocuments';
import { customerFormActions } from './localizationsCustomerFormActions';
import { form } from './localizationsForm';
import { formAction } from './localizationsFormAction';
import { submittedForm } from './localizationsSubmittedForm';
import { taskCard } from './localizationsTaskCard';
import { customerTasks } from './localizationsCustomerTasks';
import { customerTasksList } from './localizationsCustomerTasksList';
import { documentList } from './localizationsDocumentList';
import { applicationDetails } from './localizationsApplicationDetails';
import { projectAccounting } from './localizationsProjectAccounting';
import { financingFormSelection } from './localizationsFinancingFormSelection';
import { generalApplications } from './localizationsGeneralApplications';
import { localizationBankAccountList } from './localizationBankAccountsList';

const localizations: Localizations = {
    Global: {
        pageTitle: {
            en: 'Financing',
            nb: 'Finansiering',
            nn: 'Finansiering'
        },
        loading : {
            en: "Loading...",
            nb: "Laster...",
            nn: "Lastar..."
        },
        goBackLabel: {
            en: "Back",
            nb: "Tilbake",
            nn: "Tilbake"
        },
        backToAccountOverview: {
            en: 'Back to Account overview',
            nb: 'Tilbake til Kontooversikt',
            nn: 'Tilbake til Kontooversikt',
        },
        myAgreements: {
            en: 'My agreements',
            nb: 'Mine avtaler',
            nn: 'Mine avtalar',
        },
        noClearanceToSeeFinancialSupportForCompany: {
            en: 'You do not have the clearance to see the financial support and services for this company',
            nb: 'Du har ikke de nødvendige rettighetene til å se denne bedriftens finansielle støtteordninger med Innovasjon Norge',
            nn: 'Du har ikkje dei naudsynte rettighetane til å sjå denne bedriftens finansielle støtteordningar hos Innovasjon Norge',
        },
        loadingSpinner: {
            en: 'Loading..',
            nb: 'Laster inn..',
            nn: 'Lastar inn..',
        }
    },
    Navigation: {
        bankAccounts: {
            en: 'Account overview',
            nb:'Kontooversikt',
            nn: 'Kontooversikt'
        },
        caseManagement: {
            en: 'Case Management',
            nb:'Saksbehandling',
            nn: 'Saksbehandling'
        },
        documents: {
            en: 'Documents',
            nb:'Dokumenter',
            nn: 'Dokumenter'
        },
        menuAriaLabel: {
            en: 'Application details menu',
            nb: 'Søknadsdetaljer meny',
            nn: 'Søknadsdetaljar meny'
          }
    },
    ApplicationDetails: applicationDetails,
    DocumentList: documentList,
    InterestSubsidyDetails: interestSubsidyDetails,
    PaymentPlan: paymentPlan,
    LoanDetails: loanDetails,
    GrantDetails: grantDetails,
    GuaranteeDetails: guaranteeDetails,
    Footer: footer,
    FinancingFormSelection: financingFormSelection,
    TransactionOverview: transactionOverview,
    Collaterals: collaterals,
    CaseNumber: generalApplications,
    Invoices: invoicesLocalization,
    CustomerDocuments: customerDocuments,
    CustomerFormActions: customerFormActions,
    Form: form,
    FormAction: formAction,
    SubmittedForm: submittedForm,
    TaskCard: taskCard,
    CustomerTasksList: customerTasksList,
    ProjectAccounting: projectAccounting,
    CustomerTasks: customerTasks,
    BankAccountList: localizationBankAccountList,
    BankAccountSummaryCard: {
        loan: {
            en: "loan",
            nb: "lån",
            nn: "lån"
        },
        grant: {
            en: "grant",
            nb: "tilskudd",
            nn: "tilskot"
        },
        guarantee: {
            en: "guarantee",
            nb: "garanti",
            nn: "garanti",
        },
        backingguarantee: {
            en: "Guarantee for Growth",
            nb: "vekstgaranti",
            nn: "vekstgaranti",
        },
        interestsubsidy: {
            en: "interest subsidy",
            nb: "rentestøtte",
            nn: "rentestønad",
        },
        projectNo: {
            en: "reference",
            nb: "referanse",
            nn: "referanse"
        },
        loanLabel: {
            en: "balance",
            nb: "saldo",
            nn: "saldo"
        },
        grantLabel: {
            en: "available",
            nb: "tilgjengelig",
            nn: "tilgjengeleg"
        },
        guaranteeLabel: {
            en: "guaranteed",
            nb: "garantert",
            nn: "garantert"
        },
        backingGuaranteeLabel: {
            en: "guaranteed",
            nb: "garantert",
            nn: "garantert"
        },
        interestSubsidyLabel: {
            en: "Basis for calculation",
            nb: "Beregningsgrunnlag",
            nn: "Beregningsgrunnlag"
        },
        noAgreementText: {
            en: "No accounts to show.",
            nb: "Det er ingen kontoer i denne listen.",
            nn: "Det er ingen kontoar i denne lista."
        },
        cardTitleMissingPayment: {
            en: "Not paid by the due date",
            nb: "Ikke betalt til betalingsfrist",
            nn: "Ikkje betalt til betalingsfrist"
        },
        cardTitleSentForDebtCollection: {
            en: "Sent for debt collection",
            nb: "Sendt til inkasso",
            nn: "Sendt til inkasso"
        },
        goto: {
            en: "Go to",
            nb: "Gå til",
            nn: "Gå til"
        },
        formActionEmpty: {
            en: "No action available",
            nb: "Ingen handling tilgjengelig",
            nn: "Ingen handling tilgjengeleg"
        },
        formActionError400: {
            en: "An error has occured",
            nb: "En feil har oppstått",
            nn: "Ein feil har oppstått"
        },
        formActionError500: {
            en: "An error has happened, try again later",
            nb: "En feil har oppstått, prøv igjen senere",
            nn: "Ein feil har oppstått, prøv igjen seinare"
        },
        formActionOpenPanel: {
            en: "Open payout panel",
            nb: "Åpne utbetalingspanel",
            nn: "Opne utbetalingspanel"
        }
    },
    CheckBox: {
        selectInvoiceLabel:
        {
            en: "Selected invoice number",
            nb: "Valgt fakturanummer",
            nn: "Valt fakturanummer",
        },
        selectLoanLabel:
        {
            en: "Selected loan",
            nb: "Valgt lån",
            nn: "Valt lån",
        },
        noInvoiceLabel:
        {
            en: "There is no invoice that is relevant!",
            nb: "Det er ingen faktura som er aktuell!",
            nn: "Det er ingen faktura som er aktuell!",
        },
        noLoanLabel: {
            en: "There is no loan that is relevant!",
            nb: "Det er ingen lån som er aktuell!",
            nn: "Det er ingen lån som er aktuell!"
        },
        deleteLabel:{ 
            en: "Delete", 
            nb: "Slett",
            nn: "Slett",
        },
        disabledButtonTitle: {
            en: "The form can not be stored", 
            nb: "Skjemaet kan ikke lagres",
            nn: "Skjemaet kan ikkje lagrast",
        }
    }
}

export { localizations };